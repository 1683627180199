<template>
  <div>
    <v-card class="mb-4 order-list-container">
      <v-card-title class="py-2">
        <v-icon class="mr-2">mdi-clipboard-list</v-icon>
        Bestellungsliste
        <v-spacer></v-spacer>
        <span class="caption font-weight-medium mr-2 grey--text text--darken-1">
          <v-icon x-small class="mr-1">mdi-filter</v-icon>
          Einträge: {{ filteredOrdersCount }}
        </span>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row align="center" class="mb-2">
          <v-col cols="12" sm="8">
            <v-autocomplete
              v-model="filter"
              :items="filterOptions"
              label="SKU filtern"
              placeholder="Nach SKU filtern..."
              dense
              outlined
              hide-details
              clearable
              prepend-inner-icon="mdi-magnify"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-switch
              v-model="showOnlyAmazon"
              label="Nur Amazon"
              hide-details
              dense
              inset
              color="primary"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <!-- Order List -->
      <div class="order-list">
        <v-list two-line dense>
          <template v-for="(item, index) in paginatedOrders">
            <v-list-item 
              :key="item.orderId" 
              class="order-item"
              :class="{ 
                'ready-to-pack': isReadyToPack(item.orderId) && !item.blockedByHoneyMoon,
                'blocked-by-honeymoon': item.blockedByHoneyMoon 
              }"
            >
              <v-list-item-avatar class="mr-3 my-0 shop-logo" color="transparent">
                <template v-if="item.shop === 'amazon'">
                  <img src="@/assets/amazon.png" height="20px" />
                </template>
                <template v-else-if="item.shop === 'shopify' || item.shop === 'muckelmeister'">
                  <img src="@/assets/shopify.png" height="20px" />
                </template>
                <template v-else-if="item.shop === 'etsy'">
                  <img src="@/assets/etsy.png" height="20px" />
                </template>
                <template v-else-if="item.shop === 'acs'">
                  <img src="@/assets/acs.png" height="20px" />
                </template>
                <v-icon v-else>mdi-shopping</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <!-- Status Icons Row -->
                <div class="d-flex justify-end mb-1">
                  <v-tooltip bottom v-if="item.blockedByHoneyMoon">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" small v-bind="attrs" v-on="on" class="mr-2">mdi-alert-circle</v-icon>
                    </template>
                    <span>Design muss noch freigegeben werden</span>
                  </v-tooltip>
                
                  <v-tooltip bottom v-if="item.speedmark">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="blue" small v-bind="attrs" v-on="on" class="mr-2">mdi-cog</v-icon>
                    </template>
                    <span>In Produktion</span>
                  </v-tooltip>
                  
                  <v-tooltip bottom v-if="isReadyToPack(item.orderId)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="green" small v-bind="attrs" v-on="on" class="mr-2">mdi-package-variant-closed</v-icon>
                    </template>
                    <span>Bereit zum Packen</span>
                  </v-tooltip>
                  
                  <v-tooltip bottom v-if="item.shipping?.type === 'EXPRESS'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" small v-bind="attrs" v-on="on">mdi-flash</v-icon>
                    </template>
                    <span>EXPRESS</span>
                  </v-tooltip>
                </div>
                
                <!-- Order ID Header -->
                <v-list-item-title class="d-flex align-center font-weight-medium">
                  <a 
                    v-if="item.shop === 'amazon'" 
                    :href="'https://sellercentral.amazon.de/orders-v3/order/' + item.orderId"
                    target="_blank"
                    class="text-decoration-none text-truncate"
                  >
                    {{ item.orderId }}
                  </a>
                  <span v-else class="text-truncate">{{ item.orderId }}</span>
                  <span class="mx-2 grey--text text-caption">#{{ item.managedId }} {{ item.shop }}</span>
                </v-list-item-title>

                <!-- Products Info -->
                <v-list-item-subtitle class="mt-2">
                  <v-chip-group column>
                    <template v-for="product in item.products">
                      <v-chip 
                        outlined
                        :key="item.orderId + product.sku"
                        class="product-chip"
                        small
                      >
                        <v-avatar left v-if="getImage(product.sku)">
                          <v-img :src="getImage(product.sku)" contain></v-img>
                        </v-avatar>
                        <span>{{ product.sku }} <strong>({{ product.qty }}x)</strong></span>
                      </v-chip>
                    </template>
                  </v-chip-group>
                </v-list-item-subtitle>
                
                <!-- Shipping Date -->
                <div class="d-flex align-center mt-1">
                  <v-icon x-small class="mr-1">mdi-calendar</v-icon>
                  <span class="caption">
                    Versand am: <strong>{{ getShippmentDate(item.shipping?.lastShippmentDate || item.shipping?.shippmentDate) }}</strong>
                  </span>
                </div>
              </v-list-item-content>

              <!-- Expandable Details -->
              <v-list-item-action>
                <v-btn icon small @click.stop="item.showDetails = !item.showDetails">
                  <v-icon>{{ item.showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <!-- Product Details -->
            <div v-if="item.showDetails" :key="'details-'+item.orderId" class="px-4 py-2 grey lighten-4">
              <div v-for="product in item.products" :key="'detail-'+item.orderId+product.sku" class="mb-3">
                <div class="d-flex align-center">
                  <v-avatar size="40" class="mr-3" v-if="getImage(product.sku)">
                    <v-img :src="getImage(product.sku)" contain></v-img>
                  </v-avatar>
                  <div>
                    <div class="subtitle-2 font-weight-bold">{{ product.sku }}</div>
                    <div class="caption">Menge: {{ product.qty }}</div>
                  </div>
                </div>
                
                <v-divider class="my-2" v-if="product.attributes"></v-divider>
                
                <div v-if="product.attributes" class="pl-3 pt-1">
                  <v-simple-table dense class="attributes-table">
                    <tbody>
                      <tr v-for="(value, key) in product.attributes" :key="key">
                        <td class="caption font-weight-medium" width="120">{{ key }}</td>
                        <td class="caption"><span v-html="formatValue(value)"></span></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </div>
            </div>

            <v-divider :key="'divider-'+item.orderId"></v-divider>
          </template>
        </v-list>
      </div>

      <!-- Empty State -->
      <v-sheet v-if="paginatedOrders.length === 0" class="pa-6 text-center">
        <v-icon size="64" color="grey lighten-1">mdi-package-variant</v-icon>
        <div class="title grey--text text--darken-1 mt-2">Keine Bestellungen gefunden</div>
        <div class="body-2 grey--text">Versuche einen anderen Filter</div>
      </v-sheet>

      <!-- Load More Button -->
      <v-card-actions v-if="hasMoreOrders">
        <v-btn
          block
          color="primary"
          @click="loadMore"
          :loading="loading"
          class="text-none"
        >
          <v-icon left>mdi-refresh</v-icon>
          Weitere Bestellungen laden ({{ remainingOrders }} übrig)
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Prime Orders Summary -->
    <v-card outlined class="mb-4">
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="primary--text font-weight-medium">
            <v-icon left color="primary">mdi-star</v-icon>
            Prime Bestellungen Übersicht
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" md="4">
                <v-card outlined class="mb-4">
                  <v-card-title class="py-2 red--text">
                    <v-icon left small color="red">mdi-alert-circle</v-icon>
                    Noch zu produzieren
                  </v-card-title>
                  <v-card-text class="px-2">
                    <v-chip-group column>
                      <v-chip 
                        v-for="item in primeOrdersSummary" 
                        :key="'open-'+item.sku" 
                        v-if="item.pending > 0"
                        outlined
                        color="red"
                        small
                        class="ma-1"
                      >
                        <strong class="mr-1">{{ item.sku }}:</strong> {{ item.pending }}x
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-col>
                
              <v-col cols="12" md="4">
                <v-card outlined class="mb-4">
                  <v-card-title class="py-2 green--text">
                    <v-icon left small color="green">mdi-package-variant-closed-check</v-icon>
                    Bereit zum Packen
                  </v-card-title>
                  <v-card-text class="px-2">
                    <v-chip-group column>
                      <v-chip 
                        v-for="item in primeOrdersSummary" 
                        :key="'pack-'+item.sku" 
                        v-if="item.readyToPack > 0"
                        outlined
                        color="green"
                        small
                        class="ma-1"
                      >
                        <strong class="mr-1">{{ item.sku }}:</strong> {{ item.readyToPack }}x
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-col>
                
              <v-col cols="12" md="4">
                <v-card outlined class="mb-4">
                  <v-card-title class="py-2 blue--text">
                    <v-icon left small color="blue">mdi-cog</v-icon>
                    In Produktion
                  </v-card-title>
                  <v-card-text class="px-2">
                    <v-chip-group column>
                      <v-chip 
                        v-for="item in primeOrdersSummary" 
                        :key="'prod-'+item.sku" 
                        v-if="item.inProduction > 0"
                        outlined
                        color="blue"
                        small
                        class="ma-1"
                      >
                        <strong class="mr-1">{{ item.sku }}:</strong> {{ item.inProduction }}x
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <!-- Summary Table -->
            <v-card outlined elevation="0">
              <v-data-table
                :headers="[
                  { text: 'SKU', value: 'sku', sortable: true },
                  { text: 'Gesamt', value: 'total', align: 'center', sortable: true },
                  { text: 'In Produktion', value: 'inProduction', align: 'center', sortable: true },
                  { text: 'Bereit zum Packen', value: 'readyToPack', align: 'center', sortable: true },
                  { text: 'Offen', value: 'pending', align: 'center', sortable: true }
                ]"
                :items="primeOrdersSummary"
                dense
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:item.sku="{ item }">
                  <strong>{{ item.sku }}</strong>
                </template>
                
                <template v-slot:item.inProduction="{ item }">
                  <v-chip color="blue" x-small v-if="item.inProduction > 0">{{ item.inProduction }}</v-chip>
                  <span v-else>-</span>
                </template>
                
                <template v-slot:item.readyToPack="{ item }">
                  <v-chip color="green" x-small v-if="item.readyToPack > 0">{{ item.readyToPack }}</v-chip>
                  <span v-else>-</span>
                </template>
                
                <template v-slot:item.pending="{ item }">
                  <v-chip color="red" x-small v-if="item.pending > 0">{{ item.pending }}</v-chip>
                  <span v-else>-</span>
                </template>
              </v-data-table>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import images from "../../../articleImages.js";  // Stellen Sie sicher, dass der Pfad korrekt ist

export default {
  name: "orderList",
  props: {
    orders: {
      type: Array,
      required: true,
    },
    packlist: {
      type: Array,
      required: false,
    }
  },
  
  data: () => ({
    filter: null,
    filterOptions: [],
    showOnlyAmazon: true,
    pageSize: 50,
    currentPage: 1,
    loading: false,
    hasLoadedBefore: false
  }),

  created() {
    // Füge showDetails Property zu allen Bestellungen hinzu
    if (this.orders) {
      this.orders.forEach(order => {
        this.$set(order, 'showDetails', false);
      });
    }
  },

  computed: {
    filteredOrders() {
      let filtered = this.orders.filter(x => x.shipping != null);
      
      if (this.filter) {
        filtered = filtered.filter(x => 
          x.products.some(p => p.sku === this.filter)
        );
      }
      
      if (this.showOnlyAmazon) {
        filtered = filtered.filter(x => 
          x.shop === 'amazon' || x.acsCustomer === "Shirtracer"|| x.acsCustomer === "Engravo"
        );
      }
      
      return filtered;
    },

    filteredOrdersCount() {
      return this.filteredOrders.length;
    },

    paginatedOrders() {
      const start = 0;
      const end = this.currentPage * this.pageSize;
      return this.filteredOrders.slice(start, end);
    },

    hasMoreOrders() {
      return this.filteredOrders.length > this.currentPage * this.pageSize;
    },

    remainingOrders() {
      return this.filteredOrders.length - (this.currentPage * this.pageSize);
    },

    primeOrdersSummary() {
      // Nur Prime-Bestellungen filtern
      const primeOrders = this.orders.filter(order => 
        order.shipping?.type === 'PRIME'
      );
      
      // SKUs sammeln und zusammenfassen
      const summary = {};
      
      primeOrders.forEach(order => {
        order.products.forEach(product => {
          if (!summary[product.sku]) {
            summary[product.sku] = {
              sku: product.sku,
              total: 0,
              inProduction: 0,
              readyToPack: 0,
              pending: 0
            };
          }
          
          const qty = product.qty || 1;
          summary[product.sku].total += qty;
          
          if (this.isReadyToPack(order.orderId)) {
            summary[product.sku].readyToPack += qty;
          } else if (order.speedmark) {
            summary[product.sku].inProduction += qty;
          } else {
            summary[product.sku].pending += qty;
          }
        });
      });
      
      // In Array umwandeln und nach SKU sortieren
      return Object.values(summary).sort((a, b) => a.sku.localeCompare(b.sku));
    }
  },

  methods: {
    loadMore() {
      this.loading = true;
      setTimeout(() => {
        this.currentPage++;
        this.loading = false;
      }, 100);
    },

    isReadyToPack(orderId) {
      return this.packlist?.some(x => x.orderId.toString() == orderId.toString()) || false;
    },

    getShippmentDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    getImage(sku) {
      return images[sku];
    },

    formatValue(value) {
      if (typeof value === 'string' && value.includes('http')) {
        // Extrahiere den Dateinamen aus der URL
        const fileName = value.split('/').pop();
        return `<a href="${value}" target="_blank">${fileName?.toString().slice(0, 20)}...</a>`;
      }
      return `<strong>${value ? value.toString().slice(0, 20) : ''}</strong>`;
    }
  },

  watch: {
    orders: {
      immediate: true,
      handler(newOrders, oldOrders) {
        if (newOrders?.length) {
          // SKUs für Filter extrahieren
          const skus = [...new Set(
            newOrders.flatMap(o => 
              o.products?.map(p => p.sku) || []
            )
          )];
          this.filterOptions = ["", ...skus.sort()];
          
          // Speichere den ausgeklappten Zustand der alten Orders
          const expandedStateMap = {};
          if (oldOrders?.length) {
            oldOrders.forEach(order => {
              if (order.showDetails) {
                expandedStateMap[order.orderId] = true;
              }
            });
          }
          
          // Füge showDetails Property zu neuen Bestellungen hinzu
          // und behalte den ausgeklappten Zustand bei
          newOrders.forEach(order => {
            if (!order.hasOwnProperty('showDetails') || order.showDetails !== true) {
              // Setze auf true, wenn diese Bestellung vorher ausgeklappt war
              const wasExpanded = expandedStateMap[order.orderId] || false;
              this.$set(order, 'showDetails', wasExpanded);
            }
          });
        }
        // Nur beim ersten Laden zurücksetzen, nicht bei Updates
        if (!this.hasLoadedBefore) {
          this.currentPage = 1;
          this.hasLoadedBefore = true;
        }
      }
    },

    filter() {
      this.currentPage = 1;
    },

    showOnlyAmazon() {
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
/* Kartenoptimierungen */
.order-list-container {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
  overflow: hidden;
}

.v-card {
  transition: none !important; /* Verhindert Animations-Lag beim Scrollen */
}

.v-card__text {
  padding: 8px !important;
}

/* Optimierte Listendarstellung */
.order-list {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.order-list::-webkit-scrollbar {
  width: 6px;
}

.order-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.order-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.order-item {
  transition: background-color 0.2s;
}

.order-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.ready-to-pack {
  background-color: rgba(76, 175, 80, 0.15) !important;
}

.ready-to-pack:hover {
  background-color: rgba(76, 175, 80, 0.2) !important;
}

/* Shop logo styling */
.shop-logo {
  background-color: transparent !important;
  border: none !important;
}

.shop-logo img {
  max-width: 100%;
  object-fit: contain;
}

/* Blocked by Honeymoon styling */
.blocked-by-honeymoon {
  background-color: rgba(244, 67, 54, 0.1) !important;
  position: relative;
}

.blocked-by-honeymoon:hover {
  background-color: rgba(244, 67, 54, 0.15) !important;
}

.blocked-by-honeymoon::after {
  content: "Design muss freigegeben werden";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(244, 67, 54, 0.9);
  color: white;
  font-size: 11px;
  padding: 2px 8px;
  text-align: center;
  font-weight: 500;
}

/* Produkt-Chips */
.product-chip {
  margin: 2px !important;
  height: 28px !important;
}

/* Attributtabelle im Detail-Bereich */
.attributes-table {
  background: transparent !important;
}

.attributes-table td {
  border-bottom: none !important;
  padding: 2px 8px !important;
}

.attributes-table tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Verbesserungen für leeren Zustand */
.v-sheet.pa-6 {
  border-radius: 4px;
  background-color: #f5f5f5;
}

/* Verbesserte Expansion Panel */
.v-expansion-panel-header {
  min-height: 48px;
  padding: 8px 16px;
}

.v-expansion-panel-content__wrap {
  padding: 8px 16px;
}

/* Allgemeine Verbesserungen */
.v-list-item {
  height: auto !important;
  min-height: 48px;
  padding: 12px 16px;
}

.v-list-item__content {
  padding: 0;
}

/* Text truncation für Order IDs */
.text-truncate {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>